@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.splide {
  padding: 3rem 0 !important;
}

.splide__arrow--next {
  padding: 10px 30px !important ;
  background-color: rgba(94, 150, 255, 0.403) !important;
  border-radius: 20px  !important;
  -webkit-border-radius: 20px  !important;
  -moz-border-radius: 20px  !important;
  -ms-border-radius: 20px  !important;
  -o-border-radius: 20px  !important;
}

.splide__arrow--prev {
  padding: 10px 30px !important ;
  background-color: rgba(94, 150, 255, 0.403) !important;
  border-radius: 20px  !important;
  -webkit-border-radius: 20px  !important;
  -moz-border-radius: 20px  !important;
  -ms-border-radius: 20px  !important;
  -o-border-radius: 20px  !important;
}

.swal2-icon-content {
  display: none !important;
}

.swal2-title {
  color: rgba(255, 0, 0, 0.781);
  font-weight: 600;
}