 * {
 	margin: 0;
 	padding: 0;
 }

 body {
 	background-color: #ffffff;
 	overflow-x: hidden;
 }

 html {
 	scroll-behavior: smooth;
 }

 .bg-cs {
 	background: rgb(183, 203, 255);
 	background: linear-gradient(90deg, rgba(183, 203, 255, 1) 0%, rgba(0, 148, 255, 1) 35%, rgba(14, 0, 44, 1) 100%);
 }

 /* correction of code from http://csshexagon.com/ */

 .hexagon-border {
 	max-width: 70px;
 	position: relative;
	width: 300px;
 }

 .gallery {
 	--s: 150px;
 	/* control the size */
 	--g: 10px;
 	/* control the gap */
 	display: grid;
 	margin: calc(var(--s) + var(--g));
 	width: 200px;
 }

 .gallery > img {
 	grid-area: 1/1;
 	width: var(--s);
 	aspect-ratio: 0.866;
 	object-fit: cover;
 	clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0);
 	transform: translate(var(--_x, 0), var(--_y, 0)) scale(var(--_t, 1));
 	cursor: pointer;

 	transition: .2s linear;

 	-webkit-transform: translate(var(--_x, 0), var(--_y, 0)) scale(var(--_t, 1));
 	-moz-transform: translate(var(--_x, 0), var(--_y, 0)) scale(var(--_t, 1));
 	-ms-transform: translate(var(--_x, 0), var(--_y, 0)) scale(var(--_t, 1));
 	-o-transform: translate(var(--_x, 0), var(--_y, 0)) scale(var(--_t, 1));
 }

 .gallery>img:hover {
 	filter: grayscale(0);
 	z-index: 1;
 	--_t: 1.2;
 }

 .gallery>img:nth-child(1) {
 	--_x: calc(-100% - var(--g))
 }

 .gallery>img:nth-child(7) {
 	--_x: calc(100% + var(--g))
 }

 .gallery>img:nth-child(3),
 .gallery>img:nth-child(5) {
 	--_y: calc(-75% - .87*var(--g))
 }

 .gallery>img:nth-child(4),
 .gallery>img:nth-child(6) {
 	--_y: calc(75% + .87*var(--g))
 }

 .gallery>img:nth-child(3),
 .gallery>img:nth-child(4) {
 	--_x: calc(-50% - .5*var(--g))
 }

 .gallery>img:nth-child(5),
 .gallery>img:nth-child(6) {
 	--_x: calc(50% + .5*var(--g))
 }


 .navbar-bg {
 	background-color: #0061ff;
 }

 .bg-blue {
 	background-color: #f3f3f3;
 }

 .bg-outside {
 	height: 425px;
 	background-repeat: no-repeat;
 	background-size: cover;
 }

 .bg-outside-2 {
 	height: 500px;
 	background-repeat: no-repeat;
 	background-size: cover;

 }

 .bg-outside-mobile-headline {
 	height: 500px;
 	background-repeat: no-repeat;
 	background-size: cover;
 	opacity: .8;

 }

 .fade-out {
 	-webkit-animation: fade-out 1s ease-out both;
 	animation: fade-out 1s ease-out both;
 }

 .slide-top {
 	opacity: 0;
 	-webkit-animation: slide-top 1.5s ease-in both;
 	animation: slide-top 1.5s ease-in both;
 }

 .slide-top-client {
 	opacity: 0;
 	-webkit-animation: slide-top-client 1.5s ease-in both;
 	animation: slide-top-client 1.5s ease-in both;
 }

 .slide-top-count {
 	opacity: 0;
 	-webkit-animation: slide-top-count 1.5s ease-in both;
 	animation: slide-top-count 1.5s ease-in both;
 }

 .splide {
 	opacity: 0;
 	transform: translateY(57px);
 }

 .slide-left {
 	-webkit-animation: slide-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 	animation: slide-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 }

 .slide-right {
 	-webkit-animation: slide-right 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 	animation: slide-right 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 }

 .slide-right-3 {
 	-webkit-animation: slide-right-3 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 	animation: slide-right-3 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 }

 .slide-top {
 	-webkit-animation: slide-top 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 	animation: slide-top 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 }

 .slide-fwd-top {
 	-webkit-animation: slide-fwd-top 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 	animation: slide-fwd-top 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 }

 .slide-left-2 {
 	-webkit-animation: slide-right-2 0.9s ease-in-out both;
 	animation: slide-right-2 0.9s ease-in-out both;
 }

 .slide-right-portofolio {
 	-webkit-animation: slide-right-portofolio 0.9s ease-in-out both;
 	animation: slide-right-portofolio 0.9s ease-in-out both;
 }

 .tracking-in-expand {
 	-webkit-animation: tracking-in-expand 0.9s ease-in both;
 	animation: tracking-in-expand 0.9s ease-in both;
 }

 .fade-in-fwd {
 	-webkit-animation: fade-in-fwd 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
 	animation: fade-in-fwd 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
 }

 .fade-in-fwd-2 {
 	-webkit-animation: fade-in-fwd 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
 	animation: fade-in-fwd 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
 }

 .bounce-in-right {
 	-webkit-animation: bounce-in-right 1.1s both;
 	animation: bounce-in-right 1.1s both;
 }

 .bounce-top {
 	-webkit-animation: bounce-top 0.9s both;
 	animation: bounce-top 0.9s both;
 }

 .fade-in {
 	-webkit-animation: fade-in 0.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
 	animation: fade-in 0.9s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
 }

 .splide {
 	max-width: fit-content;
 }
 .splide__arrow {
	background-color: aliceblue;
	height: 20px;
	width: 30px;
 }
 .wrapper-image {
 	position: absolute;
 	top: -25px;
 	left: -200px;
 }

 /* .image-style-1{
	position: absolute ;
	top: 0;
}
.image-style-2 {
	position: absolute ;
	top: -30px;
	left: 10px;
} */

 #CarouselAbout {
 	padding: 0 !important;
 }

 #CarouselHeadline {
 	padding-top: 0 !important;
 	padding-left: 0 !important;
 	padding-right: 0 !important;
 	padding-bottom: 4rem;
 }

 .headline-benner {
 	margin-left: 100px;
 	width: 100%;
 }

 .text-headline-benner {
 	position: absolute;
 	top: -100px;
 	left: 0px;
 	width: 100%;
 }

 .text-description-benner {
 	position: absolute;
 	top: 20px;
 	left: -200px;
 	width: 100%;
 }

 .button-headline-benner {
 	position: absolute;
 	bottom: -60px;
 	left: -200px;
 }

 .about-text {
 	margin-right: -775px;
 	margin-left: 775px;
 	width: 100%;
 	overflow: hidden;
 	display: flex;
 	align-items: center;
 	flex-direction: column;
 	justify-content: center;
 }

 .slide-about {
 	margin-left: -770px;
 	width: 100%;
 	overflow: hidden;
 	display: grid;
 	align-items: center;
 }

 .about-image img {
 	width: 100%;
 	margin-left: -200px;
 }

 .portofolio-wrapper {
 	width: 100%;
 	margin-left: -1653px;
 }

 .splide__pagination__page.is-active {
 	background: #0061ff !important;
 	z-index: 1;
 }

 .splide__pagination__page.is-active:hover {
 	background: #0061ff !important;
 }

 .splide__pagination__page {
 	height: 10px !important;
 	width: 10px !important;
 	border-radius: 12.5px !important;
 	-webkit-border-radius: 12.5px !important;
 	-moz-border-radius: 12.5px !important;
 	-ms-border-radius: 12.5px !important;
 	-o-border-radius: 12.5px !important;
 }

 .number-achievement {
 	font-size: 3rem;
 	font-weight: 500;
 	margin-top: 20px;

 }

 .about-image-2 {
 	margin-left: -857px;
 	margin-right: 857px;
 }

 .portofolio-headline {
 	position: relative;
 	bottom: -100px;
 }

 .portofolio-description {
 	position: relative;
 	opacity: 0;
 	bottom: -100px;
 }

 .icon-count {
 	position: relative;
 	top: 100px;
 	opacity: 0;
 }

 .icon-services {
 	opacity: 0;
 }

 @media (max-width:600px) {

 	/* .headline-benner {
		display: none;
	} */

 	.headline-benner {
 		margin-left: 200px;
 	}

 	.text-headline-benner {
 		position: relative;
 		top: 0;

 		width: 100%;
 	}

 	.text-description-benner {
 		position: relative;
 		margin: 0;

 	}

 	.portofolio-wrapper {
 		width: 100%;
 		margin-left: -1653px;
 	}

 	.about-image img {
 		margin-left: 200px;
 	}

 	.about-image {
 		position: relative;
 		left: -200px;
 	}

 	.text-description-benner {
 		width: 100%;
 		position: relative;
 		left: -200px;
 		top: 0;
 	}

 	.about-text {
 		padding: 40px 20px;
 		display: block;
 	}

 	.bg-mobile-blue {
 		background-color: rgba(255, 255, 255, 0.823);
 		padding: 20px 0;
 		margin-top: 20px;

 	}

 	.item-why {
 		display: none;
 	}


 }

 @media (min-width : 600px) and (max-width: 768px) {
 	.slide-headline {
 		display: flex;
 		flex-direction: column-reverse;
 	}

 	.about-image img {
 		margin-left: 0;
 	}

 	.text-description-benner {
 		width: 100%;
 		position: relative;
 	}

 	.about-image {
 		width: 100%;
 	}

 	.text-headline-benner {
 		position: relative;
 		top: 0;
 	}

 	.item-why {
 		display: none;
 	}

 	.gallery {
 		width: 88px;
 	}
 }

 @media (min-width:768px) {

 	.slide-headline {
 		display: grid;
 		gap: 20px;
 		grid-template-columns: 50% 50%;
 	}

 	.text-headline-benner {
 		position: relative;
 		top: 0;
 	}

 	.text-description-benner {
 		width: 100%;
 		top: 0;
 		margin-right: 0;
 		position: relative;
 	}

 	.about-text {
 		padding: 80px 50px;
 	}

 	.about-image img {
 		margin: 0;
 	}


 	.portofolio-description {
 		padding: 0 50px;
 	}

 	.gallery {
 		width: 88px;
 		--s: 100px;
 	}
 }

 @media (min-width:1080px) {
	.gallery {
		width: 96px;
		--s: 150px;
	}
 }
 
 @media (min-width:1240px) {
	.gallery {
		width: 115px;
		--s: 150px;
	}
 }

 @media (min-width:1245px) {
	.gallery {
		width: 200px;
		--s: 150px;
	}
 }

